import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Logo from "../assets/logo.png";

const Footer = () => {
  return (
    <footer>
      <div>
        <i className="fab fa-facebook-f"></i>
        <i className="fab fa-twitter"></i>
        <i className="fab fa-instagram"></i>
      </div>
      <div className="logo">
        <NavLink to="/home">
          <img src={Logo} alt="Logo" />
        </NavLink>
      </div>
      <div>
        <ul>
          <Link to={`/About`}><li>Mentions légales & Conditions générales de vente</li></Link>
        </ul>
      </div>
      <div>
        &copy; 2024 plus4points.fr. Tous droits réservés.
      </div>
    </footer>
  );
};


export default Footer;