import PaymentStatus from '../components/PaymentStatus';



import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OSK1BCg4zAeNBqIyjiXzqDkv4skBQD4bemRIozZuZI6NVMJUGyE2YzuIsWIzymyhvo0UHG0WrPX0LZzj0kv37mm00fd7G7zQ1');






const App = () => {
  return (

    <Elements stripe={stripePromise}>
      {/* Placez ici la partie de votre application qui utilise useStripe() */}
      <PaymentStatus />
    </Elements>

  );
};

export default App;