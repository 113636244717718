import React, { useEffect, useState } from 'react';


import { Link } from 'react-router-dom';




const Slots = ({ postalCode }) => {

  ///////////////////////////////


  const [stages, setStages] = useState([]);

  useEffect(() => {


    // Fonction pour récupérer les stages en fonction du département
    const fetchStages = async () => {
      try {
        // Vérifie si un département est spécifié dans l'URL
        if (postalCode) {
          const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/sauces?postalCode=${postalCode}`);
          const data = await response.json();
          setStages(data);
        } else {
          // Si aucun département n'est spécifié, récupère tous les stages
          const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/sauces`);
          const data = await response.json();
          setStages(data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    // Appeler la fonction pour récupérer les stages
    fetchStages();

  }, [postalCode]);







///////////////////////////////

  return (
    
    <form className="slots">
      <fieldset className="slots__titles">
          <div className="slots__titles__prix">Prix</div>
          <div className="slots__titles__infos">Infos</div>
          <div className="slots__titles__dates">Dates</div>
      </fieldset>
      <fieldset className="slots__stages">
        {stages.map((stage) => (
          <div key={stage._id} className="slots__stages__product">
            <div className="slots__stages__product__prix">
              <p>{stage.prix} €</p>
            </div>
            <div className="slots__stages__product__infos">
              <p className="ville">{stage.ville}</p>
              <p className="adresse">{stage.adresse}</p>
              <p className="numero">N° stage Préfectoral : {stage.numeroPref}</p>
              <a href="/about">+ d'info</a>
            </div>
            <div className="slots__stages__product__dates">
              <p className="date">{stage.date}</p>
              <p className="place">{stage.placeDispo ? 'Places disponibles' : ''}</p>
            </div>
            <div className="slots__stages__product__btn">
            <Link to={`/stagereservation?product=${stage._id}`}>
              <button type="button">S'inscrire</button>
            </Link>
            </div>
          </div>
        ))}
      </fieldset>
    </form>
  );
};

export default Slots;
