import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import StageForm from '../components/StageForm';
import Payment from '../components/Payment';
import Map from "../assets/capturetest.PNG";




import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OSK1BCg4zAeNBqIyjiXzqDkv4skBQD4bemRIozZuZI6NVMJUGyE2YzuIsWIzymyhvo0UHG0WrPX0LZzj0kv37mm00fd7G7zQ1');






const StageReservation = () => {

  const [hideForm, setHideForm] = useState(true);
  const formHidden = () => {
    setHideForm(prevState => !prevState);
  };



  const [stage, setStage] = useState([]);

//////////////////////////////////






  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {

    const amount = stage.prix * 100;
  

    fetch("https://fourpoints-backend-render.onrender.com/create-payment-intent", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: amount }),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, [stage]);




///////////////////

const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const product = queryParams.get('product');



useEffect(() => {


  // Fonction pour récupérer les stages en fonction du département
  const fetchStage = async () => {
    try {
      const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/sauces/${product}`);
      const data = await response.json();
      setStage(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  };

  // Appeler la fonction pour récupérer les stages
  fetchStage();

}, [product]);



//////////////////




  return (
    <div className="stage-reservation">
        <h1>Réserver un stage de récupération de points à {stage.ville}</h1>
        <div className="stage-reservation__content">







              <div key={stage.id} className="stage-reservation__content__stage">
                <div className="stage-reservation__content__stage__infos">
                  <p className="ville">{stage.ville}</p>
                  <p className="adresse">{stage.adresse}</p>
                  <p className="numero">N° stage Préfectoral : {stage.numeroPref}</p>
                  <a href="#">+ d'info</a>
                </div>
                <div className="stage-reservation__content__stage__dates">
                  <p className="date">{stage.date}</p>
                  <p className="place">{stage.placeDispo ? 'Places disponibles' : ''}</p>
                </div>
                <div className="stage-reservation__content__stage__prix">
                  <p>{stage.prix} €</p>
                </div>
              </div>





            <div className="stage-reservation__content__form">





              {hideForm ? (




                <StageForm formHidden={formHidden} stage={stage}/>



                ) : (



                <div className="stage-reservation__content__form__payment">
                  {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <Payment />
                    </Elements>
                  )}
                </div>

              )}



            </div>
        </div>
    </div>
  );
};

export default StageReservation;