import React from "react";


const About = () => {
  return (
    <div className="about">






Mentions légales : 

Informations générales légales :
RECUP +4 POINTS SASU au capital de 500,00 €.
Siège social : 4 Avenue Laurent Cély 92600 Asnière-sur-Seine
 – RCS 982 707 291
 – SIRET : 982 707 291 00018
 – APE : 70.22Z
– TVA : FR12982707291

 Courriel : contact@permisapoints.fr
 Tel : +33 7 67 28 68 47


Informations légales de l’éditeur :
L’éditeur du site plus4points.fr est RECUP +4 POINTS, enregistrée au RCS sous le numéro 982 707 291, propriétaire du site.
Le prestataire assurant l’hébergement de ce site est la société OVH SAS enregistrée au RCS Lille Métropole 424 761 419 00045, ayant son siège social 2 rue Kellermann - 59100 Roubaix - France
Le site plus4points.fr relève de la législation française et internationale sur le droit d’auteur. Tous les droits de reproduction et de représentation sont réservés à RECUP +4 POINTS et ce compris les iconographies, films, animations et photographies.










Conditions générales de vente : 

RECUP +4 POINTS (ci-après dénommée « la Société »), une société SASU dont le siège social est situé au 4 Avenue Laurent Cély 92600 Asnière-sur-Seine et immatriculée au RCS sous le numéro 982 707 291, est l'éditeur du site Internet plus4points.fr. Les présentes conditions générales régissent l'inscription des utilisateurs (ci-après désignés les « Utilisateurs ») à des stages de sensibilisation à la Sécurité Routière organisés par des CSSR agréés, ainsi que l'utilisation des services associés fournis par la Société.




Article 1 : Fréquence de récupération de points des stages
Conformément à la loi n° 2011-267 du 14 mars 2011 d'Orientation et de Programmation pour la Performance de la Sécurité Intérieure, un Conducteur ne peut participer à un stage de sensibilisation au risque routier qu'une fois par an. Cela signifie qu'un Conducteur ne peut récupérer ses points qu'une fois tous les douze mois à partir de la date de participation au dernier stage.
Article 2 : Droit applicable et partenariats
Les présentes conditions générales sont régies par le droit français. RECUP +4 POINTS collabore avec des centres agréés (CSSR) par les Préfectures pour permettre l'inscription des Conducteurs aux stages de sensibilisation à la Sécurité Routière.

Article 3 : Évaluation du capital points pour les Conducteurs
La connaissance du capital de points d'un Conducteur est déclarative. Avant de s'inscrire à un stage volontaire de sensibilisation à la Sécurité Routière (pour la récupération de 4 points), le Conducteur doit vérifier que son capital points sur son permis de conduire se situe entre 1 et 8 points selon le Fichier National du Permis de Conduire. Si le Conducteur n'a jamais reçu de notification administrative (lettre 48, 48M, 48N, ou consultation du solde de points sur le site officiel du Ministère de l'Intérieur) concernant son solde de points, il doit obtenir un relevé intégral d'information (RII) auprès du Ministère de l'Intérieur. Consulter le solde de points à la Préfecture n'aura aucune incidence sur son dossier. Dans le cas où le solde de points est nul mais qu'aucune lettre recommandée d'invalidation (réf. 48SI) n'a été reçue, la participation à un stage est encore éventuel. Le service clients de RECUP +4 POINTS est disponible pour aider le Conducteur à interpréter son relevé intégral d'information sur demande. Il est crucial de noter que toute fausse déclaration de la part du Conducteur n’engage en aucun cas la responsabilité de RECUP +4 POINTS ainsi que celle de l'organisateur du stage, entraînant de ce fait le refus de tout remboursement ou transfert de stage.



Article 4 : Modalités de commande
L'inscription par les Conducteurs aux stages de sensibilisation à la Sécurité Routière se fait :
Explicitement sur le site ( plus4points.fr ) édité par RECUP +4 POINTS.
Par téléphone, auprès d’un de nos conseillers : +33 767286847,
Accessible du lundi au vendredi de 9h à 18h.

Article 5 : Disponibilité des stages
RECUP +4 POINTS accorde une grande importance à l'exactitude des informations fournies sur son Site. Tous les stages en ligne proposés à la vente disposent de places disponibles et sont accessibles en temps réel aux dates spécifiées, sauf en cas d'erreur de saisie des centres agréés.
Selon la réglementation concernant le permis à points, l'organisateur peut annuler un stage si le nombre de participants est inférieur à 6. RECUP +4 POINTS décline toute responsabilité en cas d’annulations réglementaires. Cependant, sur demande du stagiaire, RECUP +4 POINTS s'engage naturellement à proposer une solution de transfert parmi les autres stages disponibles sur le Site. Toutefois, ce transfert n'est pas automatique et nécessite l'acceptation du Conducteur. En cas de refus de la solution proposée, le Conducteur peut demander un remboursement.

Article 6 : Tarification des stages de sensibilisation à la Sécurité Routière
Les tarifs des stages de sensibilisation à la Sécurité Routière sont affichés en euros, toutes taxes comprises (TTC). L'inscription à un stage comprend la gestion complète du dossier de stage par RECUP +4 POINTS, y compris l'analyse, le conseil et l'accompagnement personnalisé du Conducteur. Elle inclut également la possibilité de transfert parmi tous les stages proposés par RECUP +4 POINTS sous certaines conditions, ainsi qu'une année d'assistance à la gestion du permis de conduire. De plus, elle comprend la participation à un stage agréé de sensibilisation à la Sécurité Routière auprès d'un CSSR.
L'inscription à un stage devient effective dès la validation du paiement qui doit être effectué uniquement en euros.Les tarifs des stages sont basés sur les prix en vigueur au moment de l'enregistrement de la commande par le Conducteur.

Article 7 : Modalités de paiement
RECUP +4 POINTS propose cinq modes de paiement pour faciliter les transactions :
Paiement par carte bancaire : CB, Visa et Mastercard via l'interface sécurisée du site. Il est important de noter que la date d'achat prise en compte pour le débit de la carte sera celle de la commande du stage, et non celle du début effectif du stage.
Paiement par chèque bancaire.
Virement bancaire vers le compte de PermisAPoints.
Paiement par carte bancaire auprès du service client.
Paiement en 3 ou 4 fois.
RECUP +4 POINTS se réserve le droit d'annuler ou de refuser toute commande émanant d'un Conducteur impliqué dans un litige de règlement en cours. Après chaque commande passée sur le Site RECUP +4 POINTS, un récapitulatif de commande sera envoyé via le site Internet et/ou par email automatique, sous réserve que le Conducteur ait fourni une adresse email valide. Cet email contient le récapitulatif de sa réservation sur le site ainsi que la convocation au stage.Si le Conducteur ne possède pas d'adresse email, toutes les correspondances lui seront adressées par courrier postal, moyennant des frais de traitement d'envoi postal facturés une seule fois, quel que soit le nombre d'envois. Dans le cas où l'adresse email fournie serait erronée, RECUP +4 POINTS ne pourra être tenue pour responsable de la non-réception de l'email de confirmation. 


Article 8 : Annulation d'une inscription - Conditions de remboursement
L’annulation, le transfert ou le remboursement peuvent être effectués à la demande du Conducteur jusqu’à 7 jours avant le premier jour du stage. Toutefois, en cas de remboursement, des frais de gestion du dossier d'un montant de 9,50€ seront déduits.
En cas de mise en attente du dossier d’inscription en salle d’attente, le Conducteur dispose de deux ans, à partir de la date de la première mise en attente, pour choisir une nouvelle date de stage ou demander un remboursement.
Toute demande d’annulation doit être effectuée par courriel à l'adresse suivante : contact@plus4points.fr ou avec une validation par téléphone auprès du service Clients de RECUP +4 POINTS au numéro +33 767286847, disponible du lundi au vendredi de 9h à 18h.
Pour les paiements par virement, le remboursement se fera par virement. Par défaut, le remboursement est effectué à l'ordre de l'utilisateur du service. Si le paiement a été effectué par un tiers, l'utilisateur doit spécifier l’identité du bénéficiaire par courrier. Toute fausse déclaration sur l'identité du bénéficiaire est passible de poursuites. Pour les paiements par carte bancaire, le remboursement s’effectue sur la carte bancaire ayant servi au paiement  dans les 7 jours suivant la demande.
Dans le cas des paiements par chèque, RECUP +4 POINTS se réserve le temps d’attendre au maximum un mois le retour des chèques impayés avant de procéder au remboursement. Celui-ci est effectué à l'ordre de l'émetteur du chèque initial.
En cas d'annulation de la session de stage par le centre agréé, le Conducteur se voit proposer une solution de transfert parmi les autres stages disponibles sur le site ou un remboursement.
En cas d'annulation de la participation à l’initiative du Conducteur, des frais d’annulation seront décomptés si celle-ci intervient entre 7 jours et 2 jours ouvrés avant la date du stage. Si l'annulation ou le transfert se fait à moins de 2 jours ouvrés avant le stage, le prix du stage est dû entièrement.
L'absence au stage ou le non-respect des horaires par le Conducteur ne donne droit ni à la récupération de points, ni au remboursement, ni au transfert sur un autre stage, à moins d'une justification par un certificat médical ou d'hospitalisation transmis dans les 48 heures à partir du dernier jour de stage.
Article 9 : Conditions de vente et responsabilité
L'inscription par carte bancaire est instantanée et confirmée immédiatement. En revanche, pour les autres modes de paiement, l'inscription n'est considérée comme définitive et confirmée qu'à réception du paiement. Si, au moment de la réception du paiement par chèque, virement ou mandat, il n'y a plus de places vacantes sur la session de stage choisie, l'inscription ne pourra être finalisée, et le Conducteur se verra alors proposer une autre session. RECUP +4 POINTS décline toute responsabilité en cas de litige survenant entre l'organisme de formation (CSSR) et le Conducteur.
Article 10 : Sécurisation des paiements en ligne
Le site RECUP +4 POINTS bénéficie d'un système de paiement en ligne sécurisé via le STRIPE.

Article 11 : Obligations des Conducteurs lors d’un stage
Pendant le déroulement du stage, le Conducteur s’engage à respecter explicitement les horaires qui lui seront communiqués par l’organisateur dès son inscription. L’organisateur se réserve le droit d’exclure à tout moment tout Conducteur dont le comportement perturberait le bon déroulement du stage, conformément à la réglementation en vigueur. En cas de non-respect des consignes, la responsabilité de RECUP +4 POINTS et de l’organisateur ne pourra en aucun cas être engagée.
La délivrance des attestations de stage relève de la seule autorité du Directeur du centre agréé et des animateurs, sans aucune intervention possible de la part de RECUP +4 POINTS. De même, la télétransmission de la participation au Stage à l’Administration française (ANTS) pour l’enregistrement des points récupérés ne peut faire l'objet d'une quelconque intervention de la part de RECUP +4 POINTS, n'ayant aucun droit réglementaire à cet égard. En conséquence, RECUP +4 POINTS ne peut voir sa responsabilité engagée seule ou aux côtés de celle de l’Organisateur.

Article 12 : Protection des données personnelles
Conformément à la loi informatique et libertés du 6 janvier 1978, vous avez le droit d'accéder, de rectifier et de vous opposer aux données personnelles vous concernant. Pour exercer ces droits, vous pouvez adresser votre demande par courrier à l'adresse indiquée ou par email. RECUP +4 POINTS s'engage à ne pas divulguer ni vendre les informations transmises.
Article 13 : Validation des Conditions Générales d’Inscription
Avant de valider son inscription, le Conducteur déclare avoir pris connaissance et accepté les présentes Conditions Générales de vente disponible sur le Site. En cas de non-validation de celles-ci, le Conducteur peut choisir de ne pas souscrire au service offert par RECUP +4 POINTS.
Article 14 : Litiges
En cas de litige, les systèmes d’informations de RECUP +4 POINTS font foi de la nature de la convention et de sa date. Le site ayant été  édité en France, le présent contrat est naturellement soumis exclusivement à la loi française.
En cas de différend lié à l'achat d'une inscription à un stage via les services de RECUP +4 POINTS, les parties s'efforceront de trouver une solution amiable. En l'absence d'accord amiable, le consommateur pourra, conformément aux articles L 612-1 et suivants du Code de la consommation, recourir gratuitement à la Médiation de la consommation en saisissant le médiateur « Médiation en Seine » par voie électronique ou postale, en suivant les instructions indiquées sur le site du médiateur.
Article 15 : Force majeure
En cas de force majeure, les obligations des parties sont suspendues de plein droit et leur responsabilité dégagée, conformément aux dispositions légales et jurisprudentielles françaises.

Article 16 : Services Relations Clients
Pour toute information ou question, RECUP +4 POINTS met à votre disposition un Service Relations Clients joignable par téléphone au +33 767286847 du lundi au vendredi de 9h à 18h, ainsi que par email ( contact@plus4points.fr).






















Politique de confidentialité (RGPD) : 



2. Protection des Données Personnelles

La Plateforme respecte les dispositions de la législation en vigueur sur la protection des données personnelles, notamment la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, ainsi que le Règlement Général sur la Protection des Données (RGPD) entré en vigueur le 25 mai 2018.

Les données personnelles collectées par la Plateforme sont utilisées dans le seul but de fournir les services proposés. Aucune donnée personnelle n'est louée, vendue ou transmise à des tiers en dehors du réseau de partenaires de la Plateforme nécessaires à l'exécution des services.

3. Utilisation des Données Personnelles

Les données personnelles collectées par la Plateforme sont utilisées aux fins suivantes :

	Traitement des commandes.
	Gestion des comptes clients.
	Communication avec les utilisateurs.
	Amélioration des services proposés.
	Respect des obligations légales et réglementaires.

4. Types de Données Collectées

La Plateforme collecte les types de données suivants :

	Données d'identification : nom, prénom, date et lieu de naissance.
	Données de contact : coordonnées postales, téléphoniques, adresse e-mail.
	Données juridiques : informations liées au permis de conduire, numéro de permis, date de validité du permis etc.
	

5. Collecte des Données

Les données personnelles sont collectées via le formulaire d'inscription sur la Plateforme ou lors de communications téléphoniques avec un conseiller client. Les utilisateurs sont informés de l'importance des données collectées à chaque étape du processus.

6. Durée de Conservation des Données

Les données personnelles sont conservées pendant une durée nécessaire à la fourniture des services et au respect des obligations légales et réglementaires.

	Les données d'identification et de contact sont conservées pendant 5 ans à compter du dernier contact.
	Les données comptables sont conservées pendant 10 ans à compter de la commande.

7. Sécurité des Données

La Plateforme garantit la sécurité des données personnelles en mettant en place des mesures physiques et technologiques appropriées pour prévenir tout accès non autorisé ou utilisation abusive des données.

8. Communication des Données

Les données personnelles ne sont jamais divulguées, transmises, louées ou vendues à des tiers à des fins de sollicitations commerciales. Elles peuvent être communiquées aux autorités compétentes dans le cadre de procédures légales.


10. Droits des Utilisateurs

Les utilisateurs disposent des droits d'accès, de rectification, d'opposition et d'effacement de leurs données personnelles. Ils peuvent exercer ces droits en contactant la société RECUP +4 POINTS aux coordonnées fournies dans les présentes CGV.	





    </div>
  );
};

export default About;