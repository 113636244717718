import {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';












const PaymentStatus = () => {






  const stripe = useStripe();
  const [message, setMessage] = useState(null);







  



const postOrder = async () => {


  const dataToStore = JSON.parse(localStorage.getItem('formData'));
  console.log(dataToStore);

  const product = dataToStore.stage._id;



    try {
      const response = await fetch(`https://fourpoints-backend-render.onrender.com/api/order/${product}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToStore),
        });

        const res = await response.json();


      
        console.log(res);
        console.log("saluuuuuuuuuuuuuut post orderrrrrr");

        
      //  if case 3 coché ne pas rediriger vers paiment mais vers page de confirmation avec recontact conseiller
        //formData.clear();
      // if tout est bon rediriger vers page de paiement 
       // let confirmationUrl = "./confirmation.html?id=" + id;
      //  window.location.href = confirmationUrl;

      }

      catch (error) {
        console.error('Erreur lors de l\'envoi des données au backend:', error);
      }













//////////////////////////////




};

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received.  Un mail de confirmation vous a été envoyé');
            postOrder();
            break;

          case 'processing':
            setMessage("Payment processing. We'll update you when payment is received.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            break;

          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);



















  return message;
};

export default PaymentStatus;