import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

//style
import "./styles/style.css";

//pages
import Home from "./pages/Home";

import Stages from "./pages/Stages";

import StageReservation from "./pages/StageReservation";

import ReservationStatus from './pages/ReservationStatus';

import About from './pages/About';

//components
import Header from "./components/Header";
import Footer from "./components/Footer";



function App() {
  return (
    <div>
      <div className="App">
        <Router>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="stages" element={<Stages />} />
                <Route path="stagereservation" element={<StageReservation />} />
                <Route path="confirmationreservation" element={<ReservationStatus />} />

                <Route path="about" element={<About />} />
            </Routes>
            <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
