import React from 'react';
import { NavLink } from 'react-router-dom'; 
import Stage from "../assets/111.png";
import Permis from "../assets/yyy.png";

import PayEmote from "../assets/payement emote.png";
import CallEmote from "../assets/appel-emote.png";
import ClientEmote from "../assets/emote-client-satisfait.png";
import StageEmote from "../assets/admin emote.png";

const SectionHome = () => {
  return (
    <div className="section-home">
      <div className="section-home__info">
        <div className="section-home__info__card">
          <img src={ClientEmote} alt="paiement" />
          <h3>98% Satisfaits</h3>
          <p>
          Notre réputation repose sur la satisfaction des conducteurs. Le sérieux de notre service et notre capacité à trouver des solutions sont régulièrement salués par nos clients.
          </p>
        </div>
        <div className="section-home__info__card">
        <img src={StageEmote} alt="paiement" />
          <h3>Stages agréés</h3>
          <p>
            Tous les lieux de stage disposent d'un agrément préfectoral. Pas de souci en cas d'imprévu ! Profitez de notre service de transfert vers un autre stage dans notre réseau de centres agréés.
          </p>
        </div>
        <div className="section-home__info__card">
        <img src={CallEmote} alt="paiement" />
          <h3>Service d’experts</h3>
          <p>
          Nos spécialistes vous guident à chaque étape de la procédure, de la gestion de votre dossier jusqu'à la récupération de vos points.
          </p>
        </div>
        <div className="section-home__info__card">
        <img src={PayEmote} alt="paiement" />
          <h3>Paiement sécurisé</h3>
          <p>
          Paiement en ligne sécurisé et vos données personnelles protégées.
          </p>
        </div>
      </div>



      <div className="section-home__stages">
        <div className="section-home__stages__infos">
          <h2>Stages de récupération de points</h2>
          <div className="section-home__stages__infos__text">

            <p>
                Un stage de récupération de points, également connu sous le nom de stage de sensibilisation à la sécurité routière, 
                vous permet de récupérer jusqu'à 4 points sur votre permis de conduire. Ce programme, animé par deux formateurs agréés, 
                se déroule sur une durée de 14 heures, réparties sur deux jours consécutifs.
            </p>
            <p>
                La participation à l'intégralité du stage est obligatoire pour obtenir l'attestation de stage. Il n'y a pas d'examen final 
                pour valider la récupération des points. Une fois le stage terminé, aucune démarche administrative supplémentaire n'est requise 
                de votre part. Vos points de permis sont automatiquement crédités dès le lendemain de la fin du stage.
            </p>
            <p>
                Pour vous inscrire à un stage de récupération de points, vous pouvez facilement réserver votre place via notre site Internet parmi 
                notre liste de stages agréés par les préfectures. Vous avez également la possibilité de réserver par téléphone en contactant nos 
                conseillers au +33 7 67 28 68 47, disponibles du lundi au vendredi de 9h à 18h.
            </p>
            <p>
                Rejoignez-nous pour un stage de récupération de points et contribuez à améliorer la sécurité routière tout en préservant votre capital 
                de points.
            </p>

          </div>

          <NavLink to="/stages">
            <button className="orange-button">
              <span>Acheter un stage</span>
            </button>
          </NavLink>

        </div>
          <img src={Stage} alt="stages" />
      </div>



      <div className="section-home__permis">
        <img src={Permis} alt="stages" />
        <div className="section-home__permis__infos">
          <h2>Bon à savoir <i class="fa-solid fa-face-smile"></i></h2>
          <p>
        Participer à un stage de récupération de points peut être une démarche volontaire ou une obligation légale. Pour un stage volontaire, 
        il est essentiel d'avoir un permis de conduire valide et de ne pas avoir effectué un stage similaire dans les 12 derniers mois.
    </p>
    <p>Avant de vous inscrire, veuillez vérifier les points suivants :</p>
    <ul>
        <li>Vous avez bien perdu des points sur votre permis de conduire.</li>
        <li>Vous n'avez pas suivi de stage de récupération de points au cours de l'année passée.</li>
        <li>Vous n'avez pas reçu la lettre 48SI indiquant l'invalidation de votre permis.</li>
        <li>Si vous êtes en période probatoire, vous avez reçu la lettre 48N vous obligeant à suivre un stage.</li>
    </ul>
          <NavLink to="/stages">
            <button className="orange-button">
              <span>Réserver maintenant</span>
            </button>
          </NavLink>
        </div>
      </div> 
    </div>
  );
};

export default SectionHome;

