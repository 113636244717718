import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';

import Logo from "../assets/logo.png";

const Header = () => {



  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };




  return (
    <header>
      <div className="logo">
        <NavLink to="/home">
          <img src={Logo} alt="Logo" />
        </NavLink>
      </div>
      <nav className="navbar">
        <NavLink to="/stages" className="navbar__links">Stage de récupération de points</NavLink>
        <NavLink to="/home" className="navbar__links">Infractions et législation</NavLink>
        <NavLink to="/home" className="navbar__links">Permis a points</NavLink>
        <NavLink to="/home" className="navbar__links">Nous contacter</NavLink>
      </nav>
      <div className="login">
          <button>Connexion</button>
      </div>
      <div className="infos">
        <p className="infos__phone"><i class="fa-brands fa-square-whatsapp"></i>+33 7 67 28 68 47</p>
        <p className="infos__dispo">Lundi au Vendredi 9h-18h</p>
      </div>


      <div className="navbar2-icon" onClick={toggleMenu}>
        <i class="fa-solid fa-bars"></i>
      </div>




      {menuOpen && (
        <nav className="navbar2">
          <NavLink to="/stages" className="navbar__links">Stage de récupération de points</NavLink>
          <NavLink to="/home" className="navbar__links">Infractions et législation</NavLink>
          <NavLink to="/home" className="navbar__links">Permis a points</NavLink>
          <NavLink to="/home" className="navbar__links">Nous contacter</NavLink>
        </nav>
      )}





    </header>
  );
};

export default Header;

